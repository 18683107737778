@font-face {
    font-family: 'FiraSans';
    src: local('FiraSans'), url(./fonts/FiraSans-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'FiraSans';
    src: url('./fonts/FiraSans-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

body {
  /*margin: 0;*/
  /*padding: 0;*/
  font-family: FiraSans, 'Helvetica Neue',Arial,Helvetica,sans-serif;
}

article .dimmed.dimmable:after {
    content: '';
    position: absolute;
    z-index: 500;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    top: 0;
    left: 0;
}

article .sticky.fixed {
    box-shadow: 0px 0.5px 9px 1.5px rgba(2, 2, 2, 0.30);
}


.ui.search .results {
    max-height: 30vh;
    overflow-y: auto;
}
