.App {
    text-align: center;
    font-family: FiraSans, sans-serif;
    /*font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;*/
}
.ui.menu, .ui.list, .ui.button, .ui.header, .ui.accordion, .ui.label, .ui.statistics, .ui.statistic>.label {
    font-family: FiraSans, 'Helvetica Neue',Arial,Helvetica,sans-serif;
}
.ui.button {
    font-weight: normal;
}
h1, h2, h3, h4, h5 {
    font-family: FiraSans, 'Helvetica Neue',Arial,Helvetica,sans-serif;
}

.notification {
    /*background-color: #555;*/
    /*color: white;*/
    /*text-decoration: none;*/
    padding: 10px 26px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
}

.notification:hover {
    background: #FFAB0F;
}

.notification .badge {
    position: absolute;
    top: -1px;
    right: -1px;
    padding: 2px 6px;
    border-radius: 50%;
    background: #FF073A;
    color: white;
}
.notification .info {
    position: absolute;
    bottom: 0px;
    right: -1px;
    padding: 2px 6px;
    border-radius: 50%;
    background: #247AFD;
    color: white;
}
